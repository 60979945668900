
<template>
	<div>
		<PlatformHeader v-show="route.name != 'account'" />

		<slot id="nuxt--content" />

		<PlatformFooter v-show="route.name != 'account'" />

	</div>
</template>

<script lang="ts">


export default {
	data() {
		return {
			route: useRoute()
		}
	},
	watch: {
		$route: {
			// dont use immeadeate bc document not defined on ssr server
			handler(to, from) {
				this.checkURL()
			},
		},
	},
	mounted() {
		this.checkURL()

	},
	methods: {
		checkURL() {
			document.body.classList.forEach((c) => {
				if (c.startsWith("body--")) document.body.classList.remove(c)
			})
			const route = this.$route.fullPath.split('/')[1];
			document.body.classList.add("body--" + route)
		}
	},
}
</script>


<style lang="scss">
@import "assets/css/platform.scss";

#nuxt--content {
	min-height: 100vh;
}

.body--space,
.body--artist,
.body--,
.body--templates,
.body--toolkit {
	background: $main;
}

.body--account {
	header {
		display: none;
	}
}
</style>
