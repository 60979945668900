<template>
	<footer>
		<div class="grid">
			<h1>Virtual Galleries for artists from artists.</h1>
			<div class="left">
				<IconLogo />
				<a href="https://ravespace.io" class="g">© 2023 by RAVE SPACE</a>
				<div class="so">
					<a href="https://twitter.com/ravespaceio" target="_blank"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_722_103)">
								<path d="M16.375 3.6155C15.7955 3.87275 15.1728 4.046 14.5191 4.12409C15.1865 3.72444 15.6991 3.09116 15.9399 2.33647C15.3158 2.70659 14.6241 2.97566 13.8878 3.12069C13.2992 2.49266 12.4585 2.10022 11.5292 2.10022C9.44303 2.10022 7.91003 4.04666 8.38122 6.06725C5.6965 5.93272 3.31562 4.64647 1.72159 2.6915C0.875031 4.14378 1.28256 6.04363 2.72106 7.00569C2.19212 6.98863 1.69337 6.8436 1.25828 6.60144C1.22284 8.09834 2.29581 9.49878 3.84981 9.8105C3.39503 9.93388 2.89694 9.96275 2.39031 9.86563C2.80113 11.1493 3.99419 12.0831 5.40906 12.1093C4.05063 13.1744 2.33913 13.6502 0.625 13.4481C2.05497 14.3649 3.754 14.8997 5.57837 14.8997C11.5778 14.8997 14.9673 9.83281 14.7626 5.28828C15.3939 4.83219 15.9419 4.26322 16.375 3.6155Z" fill="#272931" />
							</g>
							<defs>
								<clipPath id="clip0_722_103">
									<rect width="15.75" height="15.75" fill="white" transform="translate(0.625 0.625)" />
								</clipPath>
							</defs>
						</svg> </a>
					<a href="https://www.linkedin.com/company/ravespaceio" target="_blank"><svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4.98 3.5C4.98 4.881 3.87 6 2.5 6C1.13 6 0.02 4.881 0.02 3.5C0.02 2.12 1.13 1 2.5 1C3.87 1 4.98 2.12 4.98 3.5ZM5 8H0V24H5V8ZM12.982 8H8.014V24H12.983V15.601C12.983 10.931 19.012 10.549 19.012 15.601V24H24V13.869C24 5.989 15.078 6.276 12.982 10.155V8Z" fill="#272931" />
						</svg> </a>
					<a href="https://www.instagram.com/ravespace.io/" target="_blank"><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_722_100)">
								<path d="M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0V0ZM12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16ZM18.406 4.155C17.61 4.155 16.965 4.8 16.965 5.595C16.965 6.39 17.61 7.035 18.406 7.035C19.201 7.035 19.845 6.39 19.845 5.595C19.845 4.8 19.201 4.155 18.406 4.155Z" fill="#272931" />
							</g>
							<defs>
								<clipPath id="clip0_722_100">
									<rect width="24" height="24" fill="white" />
								</clipPath>
							</defs>
						</svg> </a>
				</div>
			</div>

			<div class="list">
				<h3>Navigation</h3>
				<RouterLink v-for="link in nav" :to=link.route>{{ link.name }}</RouterLink>
			</div>
		</div>
	</footer>
	<div class="subfooter">
		<div class="grid">
			<p>Made with <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M5.40179 7.69225C5.81184 7.11674 5.76609 6.51062 5.48176 5.42033C4.91943 3.26405 5.14335 2.08218 7.08188 0.682943L8.02804 0L8.25688 1.14423C8.50009 2.36027 8.89949 3.11239 9.92501 4.5591C9.96576 4.61656 9.96576 4.61657 10.0067 4.67429C11.4839 6.75834 12.0234 8.01466 12.0234 10.2961C12.0234 13.0639 9.22548 15.5489 6.02027 15.5489C2.81488 15.5489 0.0170987 13.0642 0.0170987 10.2961C0.0170987 10.2444 0.0171517 10.246 0.00838547 10.0173C-0.0594171 8.24892 0.267588 6.86486 1.59073 5.37044C1.87029 5.0547 2.18806 4.75123 2.54565 4.46085L3.33506 3.81982L3.7148 4.76316C3.99538 5.4602 4.32414 6.00813 4.69552 6.41023C5.01007 6.75079 5.24341 7.17905 5.40179 7.69225ZM2.71426 6.36533C1.69569 7.51574 1.45353 8.54069 1.50795 9.95985C1.51807 10.224 1.51776 10.2147 1.51776 10.2962C1.51776 12.1931 3.60656 14.0481 6.02014 14.0481C8.43351 14.0481 10.5225 12.1928 10.5225 10.2962C10.5225 8.38881 10.0961 7.3959 8.78214 5.54217C8.74143 5.48475 8.74143 5.48475 8.7005 5.42702C7.90254 4.30133 7.42689 3.51827 7.11221 2.64251C6.5983 3.25833 6.62852 3.87081 6.93385 5.04163C7.49617 7.19791 7.27226 8.37977 5.33372 9.77901L4.2284 10.5768L4.14553 9.21618C4.09259 8.34682 3.89581 7.75648 3.5929 7.42851C3.29362 7.10448 3.02341 6.72513 2.78109 6.29114C2.75849 6.3158 2.73621 6.34053 2.71426 6.36533Z" fill="#272931"></path>
				</svg> in Berlin</p>
		</div>
	</div>
</template>


<script setup lang="ts">

const nav = ref([
	{ route: "/templates", name: "Templates" },
	{ route: "/pricing", name: "Pricing" },
	{ route: "/toolkit", name: "Toolkit" },
	{ route: "/artist/dion", name: "Featured" },
	{ route: "/imprint", name: "Imprint" },
	{ route: "/privacy", name: "Privacy" }
])



</script>

<style lang="scss" scoped>
@import "assets/css/platform.scss";


footer {
	width: 100%;
	padding: 100px 0;
	background: $dark;
	border-top: 1px solid $main;

	.grid {
		@extend .flex-center;
		color: white;

		.left {
			@extend .flex-center;
			flex-direction: column;
			width: 200px;
			margin: 0 100px;

			svg.logo {
				width: 100px;
				height: auto;
				margin-bottom: 12px;
			}

			.g {
				color: $main;
				text-decoration: none;

				font-weight: 500;
				margin-top: 12px;
				font-size: 14px;
			}

			.so {
				@extend .flex-center;
				margin-top: 12px;

				a {
					width: 30px;
					height: 30px;
					margin: 5px;
					@extend .flex-center;
					border-radius: 100%;
					background: $main;

				}
			}
		}

		h1 {
			width: 200px;
			text-align: left;
			color: $main;
			font-size: 30px;
			line-height: 120%;
			margin: 0;
		}

		.list {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			width: 200px;

			h3 {
				color: $main;
				margin-bottom: 12px;
			}

			a {
				margin-bottom: 10px;
				color: $main;
				text-decoration: none;
				font-size: 14px;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.subfooter {
	height: 50px;
	background: $main;

	.grid {
		@extend .flex-center;
		height: 100%;

		svg {
			width: 15px;
			height: auto;
			margin: 0 2px;
			transform: translateY(2px);
		}
	}
}

@media(max-width:800px) {
	footer {
		.grid {
			flex-direction: column;

			h1 {
				text-align: center;
			}

			.left {
				margin: 50px 0;
			}

			.list {
				h3 {
					text-align: center;
				}

				a {
					text-align: center;
				}
			}
		}
	}
}
</style>
